<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchwrap-wrap">
         <div class="searchBar">
          <!--div class="box-ui-check searchbar-content">
            <div class="title" @click="modalToggle ()"><span class="user-btn-bg">{{ $t('common.detailCondi') }}</span></div>
            <div class="toggle-container" v-if="modalActive" @click="modalClose">
            </div>
              <div class="modal-wrap" v-if="modalActive" @click.stop>
                <div class="selectwrap">
                  <ul>
                    <li class="ui-check-wrap">
                      <input type="checkbox" id="a" :checked="selectAll" @click="toggleAllCheckboxes" />
                      <label for="a">
                        <i class="fas fa-check"></i>
                        <span>{{ $t('searchArea.total') }}</span>
                      </label>
                    </li>
                  </ul>
                  <ul class="checkList">
                    <li class="ui-check-wrap" v-for="item in cashTypeList" :key="item.code">
                      <input type="checkbox" :id="item.code" :checked="item.checked" @click="toggleCheckbox(item)" />
                      <label :for="item.code">
                        <i class="fas fa-check"></i>
                        <span>{{ item.label }}</span>
                      </label>
                    </li>
                  </ul>
                </div>
                <-- <div class="status-change-btn-wrap pt50">
                  <button class="btn-innerTable btn-status-change approve" type="button" @click="modalClose">{{ $t('button.close') }}</button>
                </div> ->
              </div>

          </div-->
          <div :class="'searchbar-content'">
             <div class="title"><span>{{ $t('searchArea.date') }}</span> :</div>
             <date-selector :class="'searchbar-content'"
                              @setStartDate="setStartDate"
                              @setEndDate="setEndDate"
                              :startDefault="startDefault"
                              :endDefault="endDefault"
                              :configs="dateConfigs"
                              :isOldYn="true"
                              @setOldYn="setOldYn" :oldYn="reqData.oldYn" />
          </div>
        </div>
        <div class="searchBar">
           <div :class="'searchbar-content'">
             <div class="title"><span>{{ $t('table.head.id') }}</span> :</div>
             <div>
               <input type="text" class="mr-5" v-model="reqData.memId" />
               <button class="btn-search" type="button" @click="pageSeach">
                  <i class="fas fa-search"></i>
               </button>
            </div>
           </div>
        </div>
        <memo></memo>
      </div>

      <div class="search-check">
        <div class="title"><span>{{$t('common.detailCondi')}} :</span></div>
        <div class="content">
           <div class="chk">
             <label for="a">{{ $t('searchArea.total') }}</label>
             <input type="checkbox" id="a" :checked="selectAll" @click="toggleAllCheckboxes" />
           </div>
           <template v-for="item in cashTypeList" :key="item.code">
             <div class="chk">
                 <label :for="item.code">{{ item.label }}</label>
                 <input type="checkbox" :id="item.code" :checked="item.checked" @click="toggleCheckbox(item)" />
             </div>
           </template>
        </div>
      </div>
    </div>

    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <thead>
            <tr>
              <th>{{ $t(`searchArea.regDate`) }}</th>
              <th>{{ $t(`searchArea.endDate`) }}</th>
              <th>{{ $t(`table.head.pre`) }}</th>
              <th>{{ $t(`table.head.varAmount`) }}</th>
              <th>{{ $t(`table.head.aft`) }}</th>
              <th>{{ $t('table.head.type') }}</th>
              <th>{{ $t('table.head.memo') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="cashList.length !== 0">
              <tr v-for="(item, idx) in cashList" :key="item.cashIdx">
                <td>{{replaceDateT(item.regDate)}}</td>
                <td>{{replaceDateT(item.updDate)}}</td>
                <td>{{numberWithCommas(item.preCashAmt)}}</td>
                <td>{{numberWithCommas(item.cashAmt)}}</td>
                <td>{{numberWithCommas(item.postCashAmt)}}</td>
                <td>{{item.typeDesc}}</td>
                <td>{{item.memo}}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="7">
                  {{ $t('txt.noData') }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="cashList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import TableHead from '@/components/main/table/Head.vue'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import { cashSendList, cashTransLog, memberCashStop } from '@/api/member.js'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import { getCode } from '@/api/common'
import Memo from '@/components/common/memo'

export default {
  name: 'UserCashChangeHistory',
  components: {
    TableHead,
    SearchFilter,
    Pagination,
    DateSelector,
    Memo
  },
  data () {
    return {
      modalActive: false,
      selectAll: true,
      cashTypeList: [],
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['memId', 'memNick', 'cashAmt', 'totalCashInAmt', 'totalCashOutAmt', 'progBetAmt', 'totalWlAmt', 'option']
      },
      reqData: {
        memId: '',
        startDate: '',
        endDate: '',
        oldYn: 'N'
      },
      srchFiltersProp: {
        placeholder: '아이디 검색',
        selectOptions: ''
      },
      cashList: [],
      pageInfo: {
        count_per_list: 30,
        count_per_page: 20,
        function_name: 'goPage',
        limit: 30,
        offset: 0,
        page: 1,
        tatal_list_count: 2,
        tatal_page_count: 0
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      dateProps: {
        start: true,
        end: true
      },
      startDefault: '',
      endDefault: '',
      cashTypeEtcList: []
    }
  },
  methods: {
    async getCashCode () {
      let result = await getCode('typeInfo', 'cashType')
      console.log(result)
      result.forEach(item => {
        item.checked = true
        item.label = this.$i18n.locale === 'ko' ? item.codeName : item['codeName' + this.$i18n.locale.toUpperCase()]
      })

      result = result.filter(item => {
        if (item.codeName.indexOf('홀덤') > -1 || item.codeName.indexOf('스포츠') > -1) {
          this.cashTypeEtcList.push(item.code)
          return false
        }
        return true
      })

      result.push({
        checked: true,
        code: 'etc',
        label: '기타게임'
      })

      this.cashTypeList = result
    },
    modalToggle () {
      this.modalActive = !this.modalActive
    },
    toggleAllCheckboxes () {
      this.cashTypeList.forEach((checkbox) => {
        if (checkbox.id !== 'a') {
          checkbox.checked = !this.selectAll
        }
      })
      this.selectAll = !this.selectAll
    },
    toggleCheckbox (checkbox) {
      checkbox.checked = !checkbox.checked
      this.selectAll = this.cashTypeList.every((checkbox) => checkbox.checked)
    },
    modalClose () {
      this.modalActive = false
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
        _date.setSeconds(0)
      }
      console.log(_date)
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    pageSeach () {
      this.modalClose()
      this.setTableData(1)
    },
    async setTableData (pageNum) {
      let cashTypeList = this.cashTypeList.filter(item => {
        return item.checked
      }).map(item => {
        return item.checked ? item.code : null
      })

      let isEtc = false
      cashTypeList.forEach(code => {
        if (code === 'etc') {
          isEtc = true
        }
      })

      if (isEtc) {
        cashTypeList = [
          ...cashTypeList,
          ...this.cashTypeEtcList
        ]

        cashTypeList = cashTypeList.filter(item => {
          return item !== 'etc'
        })
      }

      const data = {
        ...this.reqData,
        page: pageNum || 1,
        memId: this.reqData.memId,
        cashTypeList: cashTypeList.length > 0 ? cashTypeList : null
      }

      console.log(data)

      const listRes = await cashTransLog(data)
      console.log(listRes)
      if (listRes.resultCode === '0') {
        window.scrollTo(0, 0)
        const cashList = listRes.data.cashTransLogs
        this.cashList = cashList
        const pageInfo = listRes.data.pageInfo
        this.pageInfo = Object.assign(this.pageInfo, pageInfo)
      }
    }
  },
  async created () {
    await this.getCashCode()
    this.setStartDate()
    this.setEndDate()
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.searchwrap {flex-direction: row;justify-content: left;}
.searchwrap-wrap {display: flex;flex-direction: column;gap: 5px;}
.searchwrap-wrap .searchBar {min-width: auto;}

.search-check {display: flex;flex-direction: row;font-size: 12px;color: #252930;/*margin-left: 80px;*/}
.search-check .content {display: flex;flex-wrap: wrap;gap: 8px 20px;max-width: 685px;}
.search-check input {}
.search-check label {text-align: right;}
.search-check .chk {width: 121px;display: flex;align-items: center;gap: 3px;justify-content: end;}
/* .search-check .content .chk:nth-child(1) {order: 1;}
.search-check .content .chk:nth-child(2) {order: 2;}
.search-check .content .chk:nth-child(3) {order: 3;}
.search-check .content .chk:nth-child(4) {order: 4;}
.search-check .content .chk:nth-child(5) {order: 17;}
.search-check .content .chk:nth-child(6) {order: 5;}
.search-check .content .chk:nth-child(7) {order: 4;}
.search-check .content .chk:nth-child(8) {order: 10;}
.search-check .content .chk:nth-child(9) {order: 9;}
.search-check .content .chk:nth-child(10) {order: 8;}
.search-check .content .chk:nth-child(11) {order: 14;}
.search-check .content .chk:nth-child(12) {order: 11;}
.search-check .content .chk:nth-child(13) {order: 12;}
.search-check .content .chk:nth-child(14) {order: 13;}
.search-check .content .chk:nth-child(15) {order: 15;}
.search-check .content .chk:nth-child(16) {order: 7;}
.search-check .content .chk:nth-child(17) {order: 6;}
.search-check .content .chk:nth-child(18) {order: 18;} */

.active .btnPhoneNum {
  display: none;
}
.mainTable {
  table-layout: fixed;
}
.searchBar {min-width: 1450px;}
.toggle-container {display: flex;flex-direction: column;gap: 15px;
  gap: 15px;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.toggle-container > div {display: flex;}

.ui-check-wrap{
  font-size:1em;
  display: flex;
  padding-left:10px;
  box-sizing: border-box;
}
.ui-check-wrap:last-child{
  margin-right:0;
}
.ui-check-wrap input{
  display:none;
}

.ui-check-wrap label{
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.ui-check-wrap label:last-child{
  margin-right: 0;
}
.ui-check-wrap i{
    border: 2px solid #bfbfbf;
    background: #fff;
    border-radius: 5px;
    padding: 2px;
    font-size: 10px;
    color: #fff;
    margin-right: 4px;
}
.ui-check-wrap input[type="checkbox"]:checked + label i {
  background: #97bab8;
  border: 2px solid #97bab8;
}
.ui-check-wrap label > *:last-child{
  margin:0;
}
/* modal */
.user-btn-bg { padding: 5px 10px;  border: solid 1px #82a6a4; background-color: #e4efee; color: #82a6a4; border-radius: 3px;}
.user-btn-bg:hover {background: #82a6a4; color:#fff; }
.toggle-container > button { background: #fff; font-size: 18px; }
.modal-wrap {position: absolute; background: #fff;margin-top:0; box-sizing: border-box; left: 0px; top:40px; height: 600px; min-width: 210px; width: 210px; border: solid 1px #97bab8;  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); flex-direction: column; gap: 20px; border-radius: 3px;z-index: 10;}
.selectwrap {display:flex; flex-direction: column; width: 100%; gap: 20px;}
.selectwrap > ul {display: flex; flex-direction: column; gap: 10px; flex-wrap: wrap; position: relative; }
.selectwrap > ul:first-child li {padding-top: 5px;}
.selectwrap ul.checkList li {position: absolute; width: 100%;}
.selectwrap ul.checkList li:nth-child(1)::before { position: absolute; top: -13px; left: 10px; content: ""; border-bottom: 1px dotted #d5d5d5; width: 180px; height: 1px;}
.selectwrap ul.checkList li:nth-child(1) {top: 0px;}
.selectwrap ul.checkList li:nth-child(2) {top: 25px;}
.selectwrap ul.checkList li:nth-child(9) {top: 50px;}
.selectwrap ul.checkList li:nth-child(14) {top: 75px;}
.selectwrap ul.checkList li:nth-child(15) {top: 125px;}
.selectwrap ul.checkList li:nth-child(15)::after { position: absolute; bottom: -15px; left: 10px; content: ""; border-bottom: 1px dotted #d5d5d5; width: 180px; height: 1px;}
.selectwrap ul.checkList li:nth-child(16) {top: 100px;}

.selectwrap ul.checkList li:nth-child(7) {top: 175px;}
.selectwrap ul.checkList li:nth-child(5) {top: 200px;}
.selectwrap ul.checkList li:nth-child(5)::after { position: absolute; bottom: -15px; left: 10px; content: ""; border-bottom: 1px dotted #d5d5d5; width: 180px; height: 1px;}
.selectwrap ul.checkList li:nth-child(3) {top: 250px;}
.selectwrap ul.checkList li:nth-child(4) {top: 275px;}
.selectwrap ul.checkList li:nth-child(6) {top: 300px;}
.selectwrap ul.checkList li:nth-child(8) {top: 325px;}
.selectwrap ul.checkList li:nth-child(8)::after { position: absolute; bottom: -15px; left: 10px; content: ""; border-bottom: 1px dotted #d5d5d5; width: 180px; height: 1px;}
.selectwrap ul.checkList li:nth-child(10) {top: 375px;}
.selectwrap ul.checkList li:nth-child(11) {top: 400px;}
.selectwrap ul.checkList li:nth-child(12) {top: 425px;}
.selectwrap ul.checkList li:nth-child(13) {top: 450px;}

.selectwrap ul.checkList li:nth-child(13)::after { position: absolute; bottom: -15px; left: 10px; content: ""; border-bottom: 1px dotted #d5d5d5; width: 180px; height: 1px;}
.selectwrap ul.checkList li:nth-child(17) {top: 500px;}
.selectwrap ul.checkList li:nth-child(18) {top: 525px;}

.status-change-btn-wrap { position:absolute; right: 20px; top: 15px; }
.modal-wrap .btn-status-change.approve {width: 60px; height: 25px; font-size: 14px;}
</style>
